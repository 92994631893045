<template>
  <div class="content-list-content admin_container_default">
    <div class="content-list-table px-4">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            公開設定一覧
          </div>
        </div>
        <div class="row-input admin_center">
          <input
            type="text"
            class="form-control w-100"
            placeholder="検索"
            v-model="keywords"
            @keydown.enter.prevent="getListContents(1)"
            :disabled="disableSearch"
          />
          <div class="w-100 d-flex pt-3">
            <div class="fd-admin-listproperty-card-filter filter-date w-50">
              <date-picker
                name="date"
                v-model="start_date"
                :config="optionsDate"
                autocomplete="off"
                placeholder="日付を選択"
                @input="getListContents(1)"
              ></date-picker>
            </div>
            <div class="my-auto px-2">
              <span class="text-center" style="font-size: 24px">~</span>
            </div>
            <div class="fd-admin-listproperty-card-filter filter-date w-50">
              <date-picker
                name="date"
                v-model="end_date"
                :config="optionsDate"
                autocomplete="off"
                placeholder="日付を選択"
                @input="getListContents(1)"
              ></date-picker>
            </div>
          </div>
        </div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button class="button-create-content" v-on:click="createContent()">
            新規登録
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <!--<div class="row pt-3">
         <div class="row-input col-12">
          <div class="w-100 cp-4">
            <multiselect
              v-model="slagSelect"
              tag-placeholder="閲覧付与スラグを追加してください"
              class="col-sm-12 col-lg-12 m-0 p-0"
              placeholder="閲覧付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
                            :showNoResults="false"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
        </div> 
      </div> -->
      <!-- <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button
          v-on:click.prevent="getListContents()"
          variant="info"
          class="mr-4"
          >絞り込み検索</b-button
        >
        <b-button
          class="mr-4 btn-share-freesize"
          v-on:click="resetFieldSearch()"
          >リセット</b-button
        >
      </div> -->
      <div class="table-list-content">
        <table
          class="table-hover w-100 position-relative"
          style="margin-bottom: -25px; z-index: 100"
        >
          <thead style="">
            <tr>
              <th
                class="position-relative pr-4"
                style="
                  vertical-align: middle;
                  overflow: hidden;
                  width: 35%;
                  white-space: nowrap !important;
                  cursor: pointer;
                "
              ></th>
              <th
                class="position-relative pr-4 d-flex"
                style="
                  vertical-align: middle;
                  overflow: hidden;
                  min-width: 200px;
                  cursor: pointer;
                "
              >
                <div
                  class="background-option mx-1"
                  v-for="(item, index) in listOption"
                  :key="index"
                  :class="
                    typeOption == item.value
                      ? 'option-active'
                      : 'option-inactive'
                  "
                  @click="changeOption(item.value)"
                >
                  <p>{{ item.text }}</p>
                </div>
              </th>
              <th
                style="
                  vertical-align: middle;
                  overflow: hidden;
                  min-width: 100px;
                "
              ></th>
            </tr>
          </thead>
        </table>
      </div>
      <Tables
        variant="danger"
        :items="listContentData"
        :fields="fields"
        :itemsPerPage="50"
        :pagination="true"
        class="table-list-content"
        :columSort="{ column: 'updated_at', asc: false }"
      >
        <template v-slot:title="{ item }">
          <td style="min-width: 145px; width: 20%" class="py-2">
            <div class="text-truncate-mess">
              {{ item.title }}
            </div>
          </td>
        </template>
        <template v-slot:description="{ item }">
          <td v-html="item.description"></td>
        </template>
        <template v-slot:end_date="{ item }">
          <td>
            {{
              new Date(item.end_date).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:updated_at="{ item }">
          <td>
            {{
              item.updated_at &&
              new Date(item.updated_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:ID="{ index }">
          <td>{{ index + 1 }}</td>
        </template>
        <template v-slot:action="{ item, index }">
          <td class="py-2 pl-2 pr-1 d-flex">
            <!--<CButton
              class="mx-1 text-nowrap btn-other bg-0076ba my-auto"
              color="primary"
              square
              v-on:click="editContent(item.id, index + listContent.from)"
              variant="outline"
              size="sm"
            >
              確認
            </CButton>
            <div class="d-flex flex-column">-->
            <CButton
              class="mx-1 text-nowrap btn-other my-auto"
              color="success"
              square
              variant="outline"
              size="sm"
              v-on:click="editContent(item.id, index + listContentData.from)"
            >
              コンテンツ編集
            </CButton>
            <CButton
              class="mx-1 text-nowrap btn-other my-auto"
              color="success"
              square
              variant="outline"
              size="sm"
              v-on:click="editSaleContent(item.id)"
            >
              販売編集
            </CButton>
            <CButton
              class="mx-1 text-nowrap btn-other my-auto"
              color="success"
              square
              variant="outline"
              size="sm"
              v-on:click="urlContent(item.id)"
            >
              URL管理
            </CButton>
            <!-- </div>
            <CButton
              class="mx-1 text-nowrap"
              color="primary"
              square
              variant="outline"
              size="sm"
              v-on:click="editCatalogues(item.id)"
            >
              カバー編集
            </CButton>
            <div class="d-flex flex-column">
              <CButton
                class="mx-1 text-nowrap"
                color="primary"
                square
                v-on:click="copyStoreURL(item.id)"
                variant="outline"
                size="sm"
                style="margin-bottom: 5%"
              >
                ストアURLコピー
              </CButton> 
              <CButton
                type="button"
                class="btn mx-1 text-nowrap btn-other"
                color="success"
                square
                variant="outline"
                size="sm"
                v-on:click="copyStoreURL(item.id)"
              >
                ストアURLコピー
              </CButton>
              <CButton
                type="button"
                color="success"
                square
                variant="outline"
                size="sm"
                class="btn mx-1 text-nowrap btn-other"
                v-on:click="copySalesURL(item.id)"
              >
                販売URLコピー
              </CButton>
              <CButton
                class="mx-1 text-nowrap"
                color="primary"
                square
                v-on:click="showConfirm(item.id)"
                variant="outline"
                size="sm"
                v-b-modal.delete-modal-content
              >
                販売URLコピー
              </CButton> 
            </div> 
            <div class="d-flex flex-column">
              <CButton
                type="button"
                class="btn mx-1 text-nowrap btn-other"
                color="success"
                square
                variant="outline"
                size="sm"
                v-on:click="copyAutoURL(item.url_register_provide)"
              >
                登録URLコピー
              </CButton>
              <CButton
                type="button"
                class="btn mx-1 text-nowrap btn-other"
                color="success"
                square
                variant="outline"
                size="sm"
                v-on:click="copyAutoURL(item.url_login_provide)"
              >
                ログインURLコピー
              </CButton>
            </div> -->
            <CButton
              class="mx-1 text-nowrap btn-del my-auto"
              color="danger"
              square
              v-on:click="showConfirm(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.delete-modal-content
            >
              削除
            </CButton>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listContentData.length > 0"
      >
        <p class="font-weight-bold">
          {{ listContentData.length }}件中{{ 50 }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listContentData.last_page"
      >
        <CPagination
          v-if="listContentData.total"
          :activePage.sync="page"
          :pages="listContentData.last_page"
          align="center"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
      </div>
    </div>
    <div>
      <b-modal id="detail-modal-content" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>{{ contentById.title }}</h5>
        </div>
        <div>
          <CContainer>
            <CRow>
              <CCol lg="3" class="py-3">Description:</CCol>
              <CCol lg="9" class="py-3">{{ contentById.description }}</CCol>
            </CRow>
            <CRow>
              <CCol lg="3" class="py-3">Image:</CCol>
              <CCol lg="9" class="py-3"
                ><img
                  width="150px"
                  src="https://picsum.photos/600/300/?image=25"
              /></CCol>
            </CRow>
            <CRow>
              <CCol lg="3" class="py-3">Category:</CCol>
              <CCol lg="9" class="py-3">{{ contentById.category_name }}</CCol>
            </CRow>
            <CRow>
              <CCol lg="3" class="py-3">Price:</CCol>
              <CCol lg="9" class="py-3">{{ contentById.price }}</CCol>
            </CRow>
            <CRow>
              <CCol lg="3" class="py-3">Point:</CCol>
              <CCol lg="9" class="py-3">{{ contentById.point }}</CCol>
            </CRow>
            <CRow>
              <CCol lg="3" class="py-3">Cteate at:</CCol>
              <CCol lg="9" class="py-3">{{
                new Date(contentById.created_at).toLocaleString("ja-JP", {
                  timeZone: "Asia/Tokyo",
                })
              }}</CCol>
            </CRow>
          </CContainer>
        </div>
      </b-modal>
    </div>
    <div>
      <b-modal id="delete-modal-content" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、登録または決済URLがご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="removeContent(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-content')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
export default {
  name: "ListContent",
  components: {
    Tables,
  },
  data() {
    return {
      fields: null,
      listChecked: [],
      dataModal: "",
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      dataContent: {
        id: null,
        shop_id: null,
      },
      limit: "all",
      page: 1,
      shop_id:
        localStorage.getItem(
          Constants.ADMIN_USER_INFO +
            (this.$route.params.shopId
              ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
              : "_" + Constants.DOMAIN)
        ) &&
        JSON.parse(
          localStorage.getItem(
            Constants.ADMIN_USER_INFO +
              (this.$route.params.shopId
                ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
                : "_" + Constants.DOMAIN)
          )
        ).shop_id,
      listSlagSelect: [],
      listSlagOptions: [],
      slagSelect: null,
      keywords: null,
      optionsDate: {
        format: "YYYY-MM-DD HH:mm:ss",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      start_date: "",
      end_date: "",
      disableSearch: false,
      listContentData: [],
      listOption: [
        { value: 1, text: "価格" },
        { value: 2, text: "著者" },
        { value: 3, text: "提案" },
        { value: 4, text: "日時" },
      ],
      typeOption: 4,
    };
  },
  created() {
    if (this.detailShop && this.detailShop.id) {
      this.shop_id = this.detailShop.id;
      // const data = {
      //   shop_id: this.shop_id,
      // };
      // this.getALLlistSlag(data);
      this.getListContents(1);
    }
    this.fields = tableFields["CONTENT" + this.typeOption];
  },
  computed: {
    ...mapGetters([
      "listContent",
      "contentById",
      "message",
      "success",
      "error",
      "listSlag",
      "detailShop",
    ]),
  },
  watch: {
    detailShop() {
      if (this.detailShop && this.detailShop.id) {
        this.shop_id = this.detailShop.id;
        // const data = {
        //   shop_id: this.shop_id,
        // };
        // this.getALLlistSlag(data);
        this.getListContents(1);
      }
    },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.getListContents(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listContent() {
      this.listContentData = this.listContent;
      this.page = this.listContent.current_page;
    },
    page() {
      this.getListContents(this.page);
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
    },
  },
  methods: {
    ...mapActions({
      getListContent: "getListContent",
      getContentById: "getContentById",
      deleteContent: "deleteContent",
      // getALLlistSlag: "getALLlistSlag",
    }),
    check(e, id) {
      if (e.target.checked) {
        this.listChecked.push(id);
      } else {
        this.listChecked = this.listChecked.filter(function (item) {
          return item !== id;
        });
      }
    },
    async contentDetail(id) {
      this.getContentById(id);
    },
    getListContents(page) {
      this.listSlagSelect = [];
      if (page === undefined) {
        page = this.page;
      }
      // if (this.slagSelect !== null) {
      //   this.slagSelect.map((item) => {
      //     this.listSlagSelect.push(item.slag_id);
      //   });
      // }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          keywords: this.keywords,
          start_date: this.start_date,
          end_date: this.end_date,
          limit: this.limit,
        },
      };
      this.getListContent(formData);
    },
    createContent() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Content Change"
          : "Content Change domain",
      });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    removeContent(id) {
      this.dataContent.id = id;
      this.dataContent.shop_id = this.shop_id;
      this.deleteContent(this.dataContent);
      this.$nextTick(() => {
        this.$bvModal.hide("delete-modal-content");
        this.getListContents(this.page);
      });
    },
    editContent(id, no) {
      localStorage.setItem(Constants.NO_CONTENT, no);
      this.$router.push({
        name: this.$route.params.shopId
          ? "Edit Content Change"
          : "Edit Content Change domain",
        params: { id: id },
      });
    },
    changeLimitPage() {
      this.getListContents(1);
    },
    returnDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    editSaleContent(id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Content Sale"
          : "Content Sale domain",
        params: { id: id },
      });
    },
    urlContent(id) {
      this.$router.push({
        name: this.$route.params.shopId ? "Content Url" : "Content Url domain",
        params: { id: id },
      });
    },
    editCatalogues(id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Table Of ContentEdit"
          : "Table Of ContentEdit domain",
        params: { id: id },
      });
    },
    copyStoreURL(id) {
      const shop_id = this.shop_id;
      const hostName = window.location.origin;
      const copyText = this.$route.params.shopId
        ? hostName + "/shop/" + shop_id + "/store/" + id + "?keyword="
        : hostName + "/store/" + id + "?keyword=";
      navigator.clipboard.writeText(copyText);
      this.$store.commit("set", ["message", Constants.MESSAGE_COPY]);
      this.$store.commit("set", ["success", true]);
    },
    copySalesURL(id) {
      const shop_id = this.shop_id;
      const hostName = window.location.origin;
      if (this.$route.params.shopId) {
        const copyText = hostName + "/shop/" + shop_id + "/buy/p/" + id;
        navigator.clipboard.writeText(copyText);
      } else {
        const copyText = hostName + "/buy/p/" + id;
        navigator.clipboard.writeText(copyText);
      }
      this.$store.commit("set", ["message", Constants.MESSAGE_COPY]);
      this.$store.commit("set", ["success", true]);
    },
    resetFieldSearch() {
      this.slagSelect = [];
      const formData = {
        page: 1,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
          slag_id: [],
        },
      };
      this.getListContent(formData);
    },
    async copySlagURL(id) {
      const formData = {
        shop_id: this.shop_id,
        name_slag: "item" + id,
      };
      const dataReturn = await this.$store.dispatch("getInfoSlag", formData);
      if (dataReturn.success) {
        const hostName = window.location.origin;
        const copyText = this.$route.params.shopId
          ? "/shop/" + this.$route.params.shopId + dataReturn.data.register_url
          : dataReturn.data.register_url;
        navigator.clipboard.writeText(hostName + copyText);
        this.$store.commit("set", ["message", Constants.MESSAGE_COPY]);
        this.$store.commit("set", ["success", true]);
      }
    },
    copyAutoURL(url) {
      const hostName = window.location.origin;
      const copyText = this.$route.params.shopId
        ? "/shop/" + this.$route.params.shopId + url
        : url;
      navigator.clipboard.writeText(hostName + copyText);
      this.$store.commit("set", ["message", Constants.MESSAGE_COPY]);
      this.$store.commit("set", ["success", true]);
    },
    changeOption(value) {
      this.typeOption = value;
      this.fields = tableFields["CONTENT" + value];
    },
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == "updated_at") {
          if (!isDesc[0]) {
            return new Date(b[index]) - new Date(a[index]);
          } else {
            return new Date(a[index]) - new Date(b[index]);
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
  },
};
</script>
<style lang="scss" scoped>
.table-list-content {
  // .btn-other {
  //   width: 150px;
  // }
  // .btn-del {
  //   width: 150px;
  // }
}
.background-option {
  border: 1px solid #999999;
  border-bottom: none !important;
  position: relative;
  top: 2px;
  width: 50px;
  height: 20px;
  border-radius: 5px 5px 0 0;
  text-align: center;
}
.option-active {
  background: #999999;
  color: white;
}
.option-inactive {
  background: none;
  color: #999999;
}
</style>
